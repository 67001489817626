<template>
    <div class="pl-sales-wrap">
        <div class="pl-order-info-box">
            <p>订单号：{{info.sn}}</p>
            <span>|</span>
            <p>状态：{{statusOptions[info.status]}}</p>
            <span>|</span>
            <p>支付方式：{{options[info.pay_type]}}</p>
            <span>|</span>
            <p>下单人：{{info.user.name}}</p>
        </div>
        <div class="module-title">申请售后</div>
        <div class="pl-cotent-box">
            <div class="pl-content-row center">
                <span>货物状态:</span>
                <el-select v-model="status">
                    <el-option label="未到货" value="1"></el-option>
                    <el-option label="已到货" value="2"></el-option>
                </el-select>
            </div>
            <div class="pl-content-row center">
                <span>售后类型:</span>
                <el-radio-group v-model="param.apply_type">
                    <el-radio-button label="1" value="1">退货</el-radio-button>
                    <el-radio-button label="2" value="2">换回</el-radio-button>
                </el-radio-group>
            </div>
            <div class="pl-content-row">
                <span>问题说明:</span>
                <el-input v-model="param.problem" class="pl-input" type="textarea" maxlength="250" show-word-limit></el-input>
            </div>
            <div class="pl-content-row">
                <span>上传凭证:</span>
                <el-upload
                action
                multiple
                list-type="picture-card"
                :http-request="handleChange"
                :on-remove="removeImg"
                accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG"
                :limit="9"
                :file-list="imgs"
                >
                <i class="el-icon-plus"></i>
                </el-upload>
            </div>
        </div>
        <div class="pl-btn-box">
             <el-button type="primary" @click="submit"> 提交</el-button>
              <el-button type="primary" plain @click="$router.go(-1)">返回</el-button>
        </div>
    </div>
</template>
<script>
import OSS from 'ali-oss'
import {orderDetail,orderAftersales} from '@/api/order.js'
export default {
    data() {
        return{
            status: '未到货',
            type: '',
            imgs: [],
            param:{
                order_id: '',
                apply_type: '1',
                problem: '',
                pics:[]
            },
            id: this.$route.query.id,
            info:{user:{}},
            options: ['全部','微信','支付宝','银联'],
            statusOptions:['已取消', '待支付','待发货','待收货','售后中','售后完成','已完成'],

        }
    },
    methods: {
        async handleChange ({file}) {
      const res = await this.$http.get('/common/asset/ststoken', {})
      const client = new OSS({
        accessKeyId: res.Credentials.AccessKeyId,
        accessKeySecret: res.Credentials.AccessKeySecret,   // 你创建的Bucket时获取的
        bucket: res.bucket,  // 你创建的Bucket名称
        region: res.region_id,   //  你所购买oss服务的区域，默认oss-cn-hangzhou
        stsToken: res.Credentials.SecurityToken,
        timeout:60000,//超时时间 默认60S 自行设置
      })
      let result = await client.put(file.name, file)
      this.imgs.push({url:result.url,name: result.name})
    },
    removeImg(file,fileList) {
        this.imgs = fileList
        console.log(file,"----fi", fileList)
    },
    submit() {

        let param = Object.assign({}, this.param)
        if(!param.problem) {
            this.$message.error('请说明原因')
            return
        }
        param.order_id = this.info.id
        let pics = []
        this.imgs.forEach(item=> {
            pics.push(item.url)
        })
        param.pics = pics
        orderAftersales(param).then(()=> {
            this.$message.success('申请售后成功')
            history.go(-1)
        })
    },
    getDetail() {
            if(!this.id) return false
            orderDetail(this.id).then(res => {
                this.info = res || {}
                console.log(res)
            })
        },
    },
    created() {
        this.getDetail()
    }
}
</script>
<style lang="scss" scoped>
.pl-sales-wrap {
    .pl-order-info-box {
        width: 100%;
        height: 50px;
        background: #f3e9e9;
        color: #D9262c;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .pl-content-row {
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;
        &.center {
            align-items: center;
        }
        span {
            display: inline-block;
            width: 100px;
        }
        .pl-input {
            max-width: 80%;
            height: 150px;
            ::v-deep .el-textarea__inner {
                min-height: 150px!important;
            }
        }
    }
    .pl-cotent-box {
        background: #fff;
        padding: 15px 30px;
    }
    .pl-btn-box {
        margin-top: 30px;
    }
}
</style>
